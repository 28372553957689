#newsletter {
	background: #16171c url('../images/bg-panel-newsletter.jpg') center top no-repeat;
	background-size: cover;
	color: #ffffff;
	
	.input-wrap {
		margin-top: 50px;
		
		.input-newsletter {
			display: inline-block;
			width: rem-calc(220);
			height: 50px;
			padding: 10px 25px;
			border: none;
			border-radius: 30px;
			background: $dark-gray;
			color: #ffffff;
			outline: none;
			
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: rgba(255,255,255,0.5);
			}
			
			&::-moz-placeholder { /* Firefox 19+ */
				color: rgba(255,255,255,0.5);
			}
			
			&:-ms-input-placeholder { /* IE 10+ */
				color: rgba(255,255,255,0.5);
			}
			
			&:-moz-placeholder { /* Firefox 18- */
				color: rgba(255,255,255,0.5);
			}
		}
		
		button, .button {
			display: inline-block;
			height: 40px;
			padding: 10px 25px;
			border: none;
			border-radius: 30px;
			background: $primary-color;
			color: #ffffff;
			margin: 10px 0 0 0;
			cursor: pointer;
		}
		
		@media screen and #{breakpoint(medium)} {
			.input-newsletter {
				height: 60px;
				border-radius: 30px 0 0 30px;
			}
			
			button, .button {
				height: 60px;
				border-radius: 0 30px 30px 0;
				margin: 0 0 0 -4px;
			}
		}
	}
}
#googlemap {
	height: 250px;
	
	@media screen and #{breakpoint(medium)} {
		height: 350px;
	}
	
	@media screen and #{breakpoint(xlarge)} {
		height: 450px;
	}
}

#content.contact {
	h1, h2, .quick-contact {
		margin: 0;
		font-size: $global-font-size + rem-calc(4);
		color: $primary-color;
		font-weight: bold;
		letter-spacing: 0;
	}

	.quick-contact p {
		font-weight: bold;
	}
	
	.contact-form {
		/*background: $primary-color;*/
		/*background: #B0B0B0;*/
		background: #f0ede5;
		color: black;
		margin-top: 0;
		
		h2 {
			/*color: #ffffff;*/
          color: black;
			margin-bottom: rem-calc(20);
			font-size: rem-calc(24);
			text-transform: uppercase;
		}
		
		@media screen and #{breakpoint(xlarge)} {
			h2 {
				margin-bottom: rem-calc(40);
			}
		}
		
		input, textarea {
			/*font-size: $global-font-size;*/
          font-size: rem-calc(20);
			margin: rem-calc(0 0 8 0);
			/*color: #ffffff;*/
            color: black;
			/*background: #c25615;*/
            /*background: #484848;*/
            background: #eaeaea;
			
			&:focus {
				/*background: #BF4F0F;*/
                /*background: #383838;*/
                background: #e6e6e6;
			}
			
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				/*color: rgba(255,255,255,0.5);*/
              color: black;
			}
			
			&::-moz-placeholder { /* Firefox 19+ */
				/*color: rgba(255,255,255,0.5);*/
              color: black;
			}
			
			&:-ms-input-placeholder { /* IE 10+ */
				/*color: rgba(255,255,255,0.5);*/
              color: black;
			}
			
			&:-moz-placeholder { /* Firefox 18- */
				/*color: rgba(255,255,255,0.5);*/
              color: black;
			}
		}
		
		textarea {
			margin: rem-calc(0 0 10 0);
			height: rem-calc(154);
			
			@media screen and #{breakpoint(medium)} {
				margin: rem-calc(10 0 15 0);
			}
		}
		
		.info {
			position: relative;
			padding: rem-calc(8 0 8 37);
			font-size: $global-font-size - rem-calc(1);
			line-height: 1.1;
			
			&:before {
				display: block;
				content: "";
				position: absolute;
				left: 0;
				top: 50%;
				width: rem-calc(26);
				height: rem-calc(26);
				margin-top: rem-calc(-13);
				background: url('../images/ico-info@2x.png') no-repeat;
				background-size: contain;
			}
			
			@media screen and #{breakpoint(large)} {
				padding: rem-calc(7 0 7 37);
			}
			
			@media screen and #{breakpoint(xlarge)} {
				padding: rem-calc(14 0 14 52);
				
				&:before {
					left: rem-calc(16);
				}
			}
		}
		
		table thead, table tbody, table tfoot, table tbody tr:nth-child(even) {
			background: none;
			border: none;
		}
		
		table {
			width: auto;
			margin: 0;
		}

		th {
			padding: rem-calc(0 10 0 0);
			text-align: left;
			
			@media screen and #{breakpoint(xlarge)} {
				padding: rem-calc(0 20);
			}
		}
		
		td {
			padding: rem-calc(0);
			text-align: left;
		}
		
		.button {
			background: #ffffff;
			/*color: $primary-color;*/
            color: #484848;
			margin-top: 0;
			
			@media screen and #{breakpoint(medium)} {
				float: right;
			}
		}
	}
}
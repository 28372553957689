.contact-form {
	padding: rem-calc(20 22);
	background: #ebedf4;
	border-radius: 3px;
	margin-top: rem-calc(38);
	
	h2 {
		margin-bottom: rem-calc(15);
		font-size: $global-font-size + rem-calc(2);
		color: $black;
		text-transform: none;
	}
	
	@media screen and #{breakpoint(xlarge)} {
		padding: rem-calc(30 42 15);
		
		h2 {
			margin-bottom: rem-calc(40);
		}
	}
	
	.row {
		padding: 0 rem-calc(10);
	}
	
	.column, .columns {
		padding: 0 rem-calc(5);
	}
	
	input, textarea {
		padding: rem-calc(17);
		font-size: $global-font-size + rem-calc(2);
		background: #d5d7df;
		border: none;
		border-radius: 3px;
		box-shadow: none;
		margin: rem-calc(0 0 10 0);
		font-weight: $global-weight-light;
		transition: all .3s;
		
		@media screen and #{breakpoint(xlarge)} {
			height: rem-calc(52);
		}
		
		&:focus {
			border: none;
			outline: none;
			box-shadow: none;
			background: #E3E4EA;
		}
		
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: #242425;
		}
		
		&::-moz-placeholder { /* Firefox 19+ */
			color: #242425;
		}
		
		&:-ms-input-placeholder { /* IE 10+ */
			color: #242425;
		}
		
		&:-moz-placeholder { /* Firefox 18- */
			color: #242425;
		}
	}
	
	textarea {
		margin: rem-calc(0);
		height: rem-calc(238);
		resize: none;
	}
	
	.button {
		text-align: center;
		text-transform: none;
		margin-top: rem-calc(20);
		
		@media screen and #{breakpoint(xlarge)} {
			width: 190px;
		}
	}
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=latin,latin-ext');

body {
	min-width: 240px;
}

h1 {
	font-weight: bold;
	text-transform: uppercase;
	margin: rem-calc(0 0 20 0);
	
	@media screen and #{breakpoint(large)} {
		margin: rem-calc(0 0 45 0);
	}
}

h2 {
	font-weight: bold;
	text-transform: uppercase;
}

p {
	font-weight: $global-weight-light;
	line-height: 1.4;
	margin-bottom: rem-calc(35);
}

p.lead {
	font-weight: normal;
}

.container {
	position: relative;
	margin: 0 auto;
}

.wrapper {
	margin: 0 auto;
	max-width: rem-calc(1447);
}

.clearfix:after {
	clear: both;
}

.table {
	display: table;
	width: 100%;
}

.table-cell {
	display: table-cell;
	vertical-align: top;
}

.panel {
	padding-top: rem-calc(30);
	padding-bottom: rem-calc(30);
}

.panel-header {
	padding: rem-calc(30 0 10 0);
}

.panel-image {
	background: $medium-gray center bottom no-repeat;
	background-size: cover;
	position: relative;
	color: #ffffff;
	text-align: center;
	padding: rem-calc(60 0);
	font-size: $global-font-size + rem-calc(4);
	
	@media screen and #{breakpoint(large)} {
		padding: rem-calc(100 0);
	}
	
	h2 {
		margin: rem-calc(0 0 20 0);
      /*text-shadow: 0 0 7px black, 0 0 7px black;*/
	
		@media screen and #{breakpoint(large)} {
			margin: rem-calc(0 0 45 0);
		}
	}

  h1{
    text-shadow: 0 0 7px black, 0 0 7px black, 0 0 7px black;
  }
	
	p {
		/*text-shadow: $global-text-shadow;*/
        /*text-shadow: 0 0 7px black, 0 0 7px black, 0 0 7px black;*/
		/*font-size: rem-calc(20);*/
		font-size: rem-calc(30px);
	}
	
	.container {
		max-width: 800px;
	}
}

.image-wrap {
	position: relative;
}

@media screen and #{breakpoint(xlarge)} {
	.extra-padding {
		padding-left: rem-calc(80);
		padding-right: rem-calc(80);
	}
	
	.extra-padding-vertical {
		padding-top: rem-calc(120);
		padding-bottom: rem-calc(120);
	}
	
	.panel {
		padding-top: rem-calc(70);
		padding-bottom: rem-calc(70);
	}
	
	.panel-header {
		padding: rem-calc(50 0 20 0);
	}
	
	.xlarge-6 {
		width: 50%;
	}
	
	.xlarge-text-left {
		text-align: left;
	}
}

.custom-column {
	padding-right: .625rem;
    padding-left: .625rem;
}

.title {
	margin: rem-calc(45 0 0 0);
	text-transform: uppercase;
	font-weight: bold;
	font-size: rem-calc(30);
	letter-spacing: -1px;
	line-height: 1.2;
}

.title.light {
	font-weight: $global-weight-light;
}

.subtitle {
	margin: rem-calc(0 0 35 0);
	font-size: rem-calc(16);
	line-height: 1.2;
	font-weight: normal;
}

.suptitle {
	margin: 0;
	font-size: $global-font-size;
	line-height: 1.2;
	font-weight: $global-weight-medium;
	text-transform: uppercase;
	opacity: 0.5;
}

.suptitle + .title {
	margin: rem-calc(10 0 60 0);
}

.no-margin-top {
	margin-top: 0;
}

.no-margin-bottom, .suptitle + .title.no-margin-bottom {
	margin-bottom: 0;
}

.button {
	text-transform: uppercase;
	font-weight: bold;
}

.tooltip {
	box-shadow: 0 0 10px 1px rgba(0,0,0,0.1);
}

@media screen and #{breakpoint(large)} {
	.scroller-button {
		width: rem-calc(75);
		height: rem-calc(90);
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 5;
		cursor: pointer;
		background: url('../images/bg-scroller.png') right bottom no-repeat;
		
		> span {
			display: block;
			width: rem-calc(17);
			height: rem-calc(31);
			border: rem-calc(2) solid #ffffff;
			border-radius: rem-calc(16);
			position: absolute;
			right: rem-calc(22);
			bottom: rem-calc(20);
			
			> span {
				display: block;
				content: "";
				width: rem-calc(3);
				height: rem-calc(7);
				margin: rem-calc(4) auto;
				background: rgba(255,255,255,0.5);
				animation: mousescroll 3s infinite;
				-webkit-animation: mousescroll 3s infinite;
				
				&:before {
					display: block;
					content: "";
					width: rem-calc(1);
					height: rem-calc(7);
					margin: 0 auto;
					background: #ffffff;
				}
			}
		}
	}
}

.top-bar, .top-bar ul {
	background-color: white;
}

.bigger-paragraph{
	font-size: rem-calc(18px);
}

#hp-video{
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -1000;
	overflow: hidden;
}

/*#autohide {
  -moz-animation: cssAnimation 0s ease-in 15s forwards;

  -webkit-animation: cssAnimation 0s ease-in 15s forwards;

  -o-animation: cssAnimation 0s ease-in 15s forwards;

  animation: cssAnimation 0s ease-in 15s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes cssAnimation {
  to {
    width:0;
    height:0;
    overflow:hidden;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    width:0;
    height:0;
    visibility:hidden;
  }
}*/
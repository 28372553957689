.gal-image {
	display: inline-block;
	position: relative;
	
	&:before {
		display: block;
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		transition: all .3s;
		-webkit-transition: all .3s;
	}
	
	&:hover {
		&:before {
			background: rgba(9,9,9,0.38);
		}
		
		&:after {
			display: block;
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			width: rem-calc(80);
			height: rem-calc(80);
			border-radius: rem-calc(80);
			margin: rem-calc(-40 0 0 -40);
			background: $primary-color url('../images/ico-magnifier.png') 50% 50% no-repeat;
		}
	}
}
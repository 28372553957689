#content {
	padding: rem-calc(20 0);
	
	@media screen and #{breakpoint(medium)} {
		padding: rem-calc(30 0);
	}
	
	@media screen and #{breakpoint(large)} {
		padding: rem-calc(60 0);
	}
	
	h1 {
		margin: rem-calc(0 0 10 0);
		font-size: rem-calc(32);
		text-transform: none;
		color: $black;
		letter-spacing: -1px;
	}
	
	p {
		margin-bottom: rem-calc(20);
		font-size: rem-calc(22);
	}
}
#fly {
	background: url('../images/bg-panel-fly.jpg') center bottom no-repeat;

	.row {
		max-width: 121.42857rem - rem-calc(145);
		margin: 0 auto;
	}
}

.image-wrap {
	.image-flying {
		position: absolute;
		bottom: 0;
		left: rem-calc(-58);
	}
}
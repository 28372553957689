#chess {
	padding: rem-calc(30 0);
	/*background: $black url('../images/bg-panel-chess.jpg') center bottom no-repeat;*/
	background: #ececea;
	background-size: cover;
	color: #ffffff;
	
	h2 {
		font-weight: $global-weight-light;
	}
	
	.button {
		margin-top: rem-calc(40);
	}

	.chess-content {
		padding: rem-calc(10 0);
		font-size: rem-calc(12);
		vertical-align: bottom;
		/*color: #ffffff;*/
		color: black;
		text-decoration: none;
		position: relative;
		
		h2 {
			margin: rem-calc(0 0 30 0);
			/*font-size: rem-calc(16);*/
			font-size: rem-calc(25);
			font-weight: normal;
			text-transform: uppercase;
		}

		p{
			font-size: rem-calc(70);
			margin-bottom: 0;
		}
		
		// medium
		@media screen and #{breakpoint(medium)} {
			padding: rem-calc(10 30);
		}
	}
	
	a.chess-content {
		&:after {
			/*display: block;*/
			display: none;
			content: "+";
			font-size: rem-calc(34);
			line-height: 1;
			position: absolute;
			bottom: rem-calc(10);
			right: rem-calc(10);
		}
	}
	
	@media screen and #{breakpoint(medium down)} {
		.table, .table-cell {
			display: block;
		}
	}
	
	@media screen and #{breakpoint(medium)} {
		.chess-content {
			padding-top: rem-calc(30);
		}
		
		.table-cell {
			float: left;
		}
		
		.clear-medium {
			clear: both;
		}
	}
	
	@media screen and #{breakpoint(large)} {
		.chess-content {
			padding-top: rem-calc(50);
		}
		
		.clear-medium {
			clear: none;
		}
	}
	
	@media screen and #{breakpoint(xlarge down)}{
		.table-cell {
			vertical-align: middle;
		}
		
		.extra-padding-vertical {
			padding: rem-calc(50 0 0 0);
			clear: both;
		}
	}
}

.reveal[id^="chess-content"] {
	h3 {
		margin-bottom: rem-calc(20);
		font-size: rem-calc(22);
		text-transform: uppercase;
	}
	
	img {
		margin-bottom: rem-calc(30);
		
		@media screen and #{breakpoint(large)}{
			margin-bottom: 0;
		}
	}
	
	@media screen and #{breakpoint(medium down)}{
		.column {
			padding: 0;
		}
	}
}
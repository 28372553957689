.news-list {
	max-width: 1450px;
	margin: rem-calc(40) auto;
	
	.date {
		font-size: $global-font-size + rem-calc(1);
		font-weight: $global-weight-light;
	}
	
	.news-label {
		margin: rem-calc(5 0 35 0);
		font-size: $global-font-size + rem-calc(2);
		font-weight: bold;
	}
	
	.news-item {
		margin: 0;
		background: black;
		color: #ffffff;
		position: relative;
		font-size: $global-font-size;
		overflow: hidden;
		
		.news-content {
			padding: rem-calc(80 0 10 0);
			position: relative;
			z-index: 3;
			
			p {
				margin: 0;
			}
		}
		
		@media screen and #{breakpoint(medium)} {
			height: rem-calc(330);
			
			.news-content {
				padding: rem-calc(156 26 36 26);
			}
		}
		
		.news-image {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			background: url('../images/news-image-1.jpg') no-repeat;
			background-size: cover;
			transition: all 1s;
			
			&:before {
				display: block;
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+45,000000+100&0+0,0.55+45,0.8+100 */
				background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.55) 45%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 45%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 45%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
			}
		}
		
		&:hover .news-image {
			transform: scale(1.1);
		}
	}
}
#intro {
	max-width: 100%;
    overflow: hidden;
    height: 100vh;
    background: #000 url("../images/video.jpg") center top no-repeat;
    background-size: cover;
    position: relative;
	
	video {
		width: 100%;
		height: auto;
		display: block;
		margin: 0;
		padding: 0;
	}
	
	video source {
		width: 100%;
		height: auto;
		display: block;
		margin: 0;
		padding: 0;
	}
	
	&.video-response video {
		width: auto;
		height: 100%;
	}
	
	#video-pager {
		width: 100%;
		padding: 12px 0;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 2;
		background: #000000;
		background: rgba(0, 0, 0, 0.5);
		
		.container {
			width: 100%;
			
			@media screen and #{breakpoint(large)} {
				display: table;
				height: 8.85714rem;
			}
		}
		
		.intro-content {
			display: block;
			padding: .71429rem;
			font-size: 1.28571rem;
			font-weight: 300;
			line-height: 1.2;
			position: relative;
			z-index: 3;
			color: #ffffff;
			
			&:hover {
				background: rgba(0, 0, 0, 0.5);
				color: #f37021;
			}
			
			strong {
				font-weight: bold;
				letter-spacing: -0.7px;
				font-size: 1.42857rem;
			}
			
			@media screen and #{breakpoint(medium)} and #{breakpoint(large down)} {
				float: left;
				margin-right: rem-calc(20);
			}
			
			@media screen and #{breakpoint(large)} {
				display: table-cell;
				vertical-align: middle;
				font-weight: 600;
				padding: .71429rem 2.14286rem;
				
				strong {
					font-size: 2rem;
				}
			}
		}
	}
}
#product-intro {
	padding: rem-calc(100 0);
	background-image: url('../images/bg-product.jpg');

	@media screen and (min-height: 500px) {
		display: table;
		width: 100%;
		height: rem-calc(460);
		
		.container {
			display: table-cell;
			vertical-align: middle;
		}
	}
	
	@media screen and (min-height: 800px) {
		
		height: rem-calc(743);
	}
	
	h1 {
		font-size: rem-calc(48);
		/*text-shadow: 1px 1px 15px rgba(0,0,0,0.4);*/
		text-transform: uppercase;
		line-height: 1.1;
		text-shadow: 4px 4px 8px black, 4px 4px 8px black;
	}
	
	p {
		margin: 0;
		font-weight: normal;
		/*text-shadow: 1px 1px 10px rgba(0,0,0,0.9);*/
		text-shadow: 4px 4px 8px black, 4px 4px 8px black;
		text-transform: uppercase;
	}
	
	@media screen and #{breakpoint(large)} {
		h1 {
			font-size: rem-calc(84);
			letter-spacing: -2px;
		}
	}
	
	.container {
		max-width: 90%;
	}
	
	&.intro-cavalon {
		background-image: url('../images/bg-product-cavalon.jpg');
	}
	
	&.intro-calidus {
		background-image: url('../images/bg-product-calidus.jpg');
	}
}

.stripe {
	padding: rem-calc(17 0);
	
	.button {
		padding: rem-calc(10 45);
		
		@media screen and #{breakpoint(medium)} {
			float: right;
			margin: rem-calc(0 0 0 40);
		}
	}
	
	.scroller {
		display: block;
		padding: rem-calc(6 48 6 0);
		font-weight: bold;
		text-transform: uppercase;
		position: relative;
		cursor: pointer;
		
		&:after {
			display: block;
			content: "";
			width: rem-calc(26);
			height: rem-calc(26);
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: rem-calc(-13);
			background: url('../images/ico-scroller@2x.png') no-repeat;
			background-size: contain;
		}
		
		@media screen and #{breakpoint(medium)} {
			display: inline-block;
		}
	}
}

#flying {
	background-image: url('../images/bg-product-flying.jpg');
	font-size: $global-font-size;
	
	.col-image {
		width: rem-calc(107);
		height: rem-calc(107);
		margin: rem-calc(60 0);
		position: relative;
		
		img {
			position: relative;
			z-index: 2;
		}
		
		&:before {
			display: block;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: rem-calc(107);
			height: rem-calc(107);
			/*background: $primary-color;*/
			background: white;
			transform: rotate(5deg);
			-webkit-transform: rotate(5deg);
		}
	}
	
	.columns:nth-child(2n) {
		.col-image {
			&:before {
				transform: rotate(-7deg);
				-webkit-transform: rotate(-7deg);
			}
		}
	}
	
	.columns:nth-child(3n) {
		.col-image {
			&:before {
				transform: rotate(-11deg);
				-webkit-transform: rotate(-11deg);
			}
		}
	}
}

#specification {
	.image-flying {
		left: 0;
	}
	
	table {
		margin: 0;
	}
}

#gallery {
	.title {
		margin-top: 0;
	}
	
	.gallery {
		margin: rem-calc(70) auto 0 auto;
		
		[class*="grid-page-"] {
			visibility: hidden;
			position: absolute !important;
			z-index: -999;
		}
		
		[class*="grid-page-"].show {
			visibility: visible;
			position: relative !important;
			z-index: auto;
		}
		
		.grid-item, .grid-sizer {
			width: 50%;
			
			img {
				width: 100%;
			}
		}
		
		.grid-item--width2 {
			width: 100%;
		}
		
		.placeholder {
			width: 100%;
			
			> a {
				display: block;
				background: $primary-color;
				
				> span {
					display: block;
					padding: rem-calc(20);
					color: #ffffff;
					text-transform: uppercase;
					font-weight: bold;
					font-size: rem-calc(28);
					line-height: 1.1;
				}
			}
		}
		
		@media screen and #{breakpoint(medium)} {
			.grid-item, .grid-sizer {
				width: 33.33%;
			}
			
			.grid-item--width2 {
				width: 66.66%;
			}
			
			.placeholder {
				width: 100%;
			}
		}
		

		@media screen and #{breakpoint(large)} {
			.grid-item, .grid-sizer {
				width: 25%;
			}
			
			.grid-item--width2 {
				width: 50%;
			}
			
			.placeholder {
				width: 25%;
				
				> a {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					
					> span {
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						padding: rem-calc(40);
					}
				}
			}
		}
	}
}

.product-text{
	p{
		font-size: rem-calc(20);
	}
}

.extra-padding{
	&.bigger-paragraph{
		font-size: rem-calc(20);
	}
}
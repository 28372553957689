@keyframes mousescroll {
  20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(3px);
  }
  60% {
    transform: translateY(0);
  }
}

@-webkit-keyframes mousescroll {
  20% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(3px);
  }
  60% {
    -webkit-transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  30% {
    transform: scale(0.3);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }
  90% {
    transform: scale(1);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 0.0;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  30% {
    -webkit-transform: scale(0.3);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }
  90% {
    -webkit-transform: scale(1);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.0;
  }
}
.sticky-top-bar {
	@media screen and #{breakpoint(large)} {
		padding-top: rem-calc(106);
	}
}

.top-bar {
	font-size: $global-font-size;
	
	// large up
	@media screen and #{breakpoint(large)} {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		z-index: 101;
		
		&-left {
			padding-left: rem-calc(160);
		}
	}
	
	// xlarge up
	@media screen and #{breakpoint(xxlarge)} {
		&-left {
			padding-left: rem-calc(316);
		}
	}
	
	.title-bar {
		// medium down (small)
		@media screen and #{breakpoint(medium down)} {
			padding: 0;

			button {
				padding: rem-calc(17) .71429rem;
			}
		}
	}
	
	&--logo {
		display: block;
		float: left;
		background: $primary-color;
		
		&:after {
			display: block;
			content: "";
			width: rem-calc(120);
			height: rem-calc(31);
			margin: rem-calc(10 14 14 10);
			background: url('../images/logo.png') no-repeat;
			background-size: contain;
		}
		
		// medium up
		@media screen and #{breakpoint(medium)} {
			float: none;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 10;
			width: rem-calc(213);
			height: rem-calc(102);
			margin: rem-calc(-10 0 0 -20);
			background: url('../images/logo-header.png') no-repeat;
			background-size: contain;
			
			&:after {
				width: rem-calc(123);
				height: rem-calc(32);
				margin: rem-calc(26 0 0 36);
			}
		}
		
		// large
		@media screen and #{breakpoint(large)}{
			margin: rem-calc(0 0 0 -35);
		}
		
		// xxlarge
		@media screen and #{breakpoint(xxlarge)} {
			width: rem-calc(314);
			height: rem-calc(153);
			background-size: auto;
			margin: rem-calc(0);
			
			&:after {
				width: rem-calc(163);
				height: rem-calc(42);
				margin: rem-calc(36 0 0 61);
				background-size: auto;
			}
		}
	}
	
	a {
		color: $white;
		text-transform: uppercase;
	}
	
	.title-bar {
		button {
			outline: none;
			color: $white;
			
			.menu-icon {
				color: $white;
				height: 17px;
				
				&:hover::after {
					background: $white;
					box-shadow: 0 7px 0 $white, 0 14px 0 $white;
				}
			}
		}
		
		.title-bar-title {
			color: $white;
			text-transform: uppercase;
			font-size: $global-font-size + rem-calc(1);
			line-height: rem-calc(16);
		}
	}

	.menu {
		text-align: center;
		padding: rem-calc(10 0 0 0);

		> li {
			display: block;
			color: black;
			padding-left: rem-calc(0);
			padding-right: rem-calc(0);
			
			a {
				color: black;
				font-size: rem-calc(15);
				font-weight: bold;
				padding-left: rem-calc(9);
				padding-right: rem-calc(9);
			}

			&.social {
				padding-bottom: rem-calc(10);
			}
		}
		
		.button {
			padding: rem-calc(15 30);
			font-size: $global-font-size - rem-calc(1);
			font-weight: bold;
			display: inline-block;
		}
		
		@media screen and #{breakpoint(large)} {
			padding: 0;
			
			li:hover, li.is-active {
				/*background: #1c1d20;*/
				/*background: #B0B0B0;*/
				background: white;
				border-top: 3px solid $primary-color;
			}
			
			li {
				display: inline-block;
				padding: rem-calc(25 0);
				/*border-top: 3px solid $secondary-color;*/
				/*border-top: 3px solid #B0B0B0;*/
				border-top: 3px solid transparent;
				background: none;

				&.social {
					padding-bottom: rem-calc(0);
				}
			}
			
			li.no-hover, li.no-hover:hover {
				border-top: none;
				background: none;
			}
			
			li.is-dropdown-submenu-parent {
				padding-right: rem-calc(18);
			}
		
			.button {
				margin: rem-calc(-5 0);
			}
		}
		
		@media screen and (min-width: 90em) {
			li {
				padding: rem-calc(38 0 41 0);
			}
		}
		
		@media screen and (min-width: 1800px) {
			li {	
				a {
					padding-left: rem-calc(25);
					padding-right: rem-calc(25);
				}
			}
		}
	}
	
	.submenu {
		a {
			color: #ffffff;
		}
	}
	
	.dropdown.menu>li.is-dropdown-submenu-parent>a::after {
		border: none;
		width: 8px;
		height: 5px;
		background: url('../images/ico-dropdown@2x.png') no-repeat;
		background-size: contain;
	}
	
	.become-pilot {
		margin: rem-calc(0 0 20 0);
	}
	
	.menu li.social {
		.youtube, .instagram, .facebook {
			display: inline-block;
			height: rem-calc(16);
			padding: 0;
			margin: rem-calc(0 8);
			box-sizing: content-box;
			
			&:before {
				display: block;
				content: "";
				width: rem-calc(23);
				height: rem-calc(16);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
				margin: 0 auto;
			}
			
			// large up
			@media screen and #{breakpoint(large)} {
				padding: rem-calc(8 4 0 4);
				margin: 0;
			}
			
			@media screen and #{breakpoint(xlarge)} {
				padding: rem-calc(8 6 0 6);
			}
			
			@media screen and #{breakpoint(xxlarge)} {
				padding: rem-calc(8 8 0 8);
			}
		}
		
		.youtube:before {
			background-image: url('../images/ico-youtube@2x.png');
		}
		
		.instagram:before {
			width: rem-calc(16);
			background-image: url('../images/ico-instagram@2x.png');
		}
		
		.facebook:before {
			width: rem-calc(8);
			background-image: url('../images/ico-facebook@2x.png');
		}
	}
	
	.flag {
		margin: rem-calc(18) auto;
		position: relative;
		/*background: $secondary-color;*/
        /*background: #B0B0B0;*/
		background: white;
	
		span, a {
			display: inline-block;
			width: rem-calc(24);
			height: rem-calc(24);
			margin: rem-calc(0 6);
			padding: 0;
			border-radius: 50%;
			overflow: hidden;
			position: relative;
			background-repeat: no-repeat;
			
			// large up
			@media screen and #{breakpoint(large)} {
				display: block;
				margin: 0;
			}
		}
		
		span {
			&:after {
				display: block;
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				transition: all .3s;
				/*background: rgba(0,0,0,0.37);*/
				border: 1px solid #f37021;
			}
			
			// large up
			@media screen and #{breakpoint(large)} {
				&:before {
					background: none;
				}
			}
		}
		
		.cz {
			background: url('../images/ico-cz.png');
		}
		
		.en {
			background: url('../images/ico-en.png');
		}
		
		.flags {
			display: inline-block;
			
			// large up
			@media screen and #{breakpoint(large)} {
				display: none;
				position: absolute;
				left: 0;
				top: 100%;
				z-index: 5;
				
				a {
					margin: rem-calc(7 0);
					padding: 0;
				}
			}
		}
		
		// large up
		@media screen and #{breakpoint(large)} {
			width: rem-calc(24);
			height: rem-calc(24);
			margin: rem-calc(4 20 0 14);
			
			&:hover, .active {
				span:before {
					display: block;
					content: "";
					width: rem-calc(12);
					height: rem-calc(8);
					position: absolute;
					left: rem-calc(6);
					top: rem-calc(8);
					z-index: 2;
					background: url('../images/ico-dropdown-flag.png') no-repeat;
				}
	
				span:after {
					background: rgba(0,0,0,0.37);
				}
				
				.flags {
					display: block;
				}
			}
		}
	}
	
	// large up
	@media screen and #{breakpoint(large)} {
		.top-bar-right .menu > li {
			float: right;
			margin: 0;
		}
	}
	
	// large down (small, medium)
	@media screen and #{breakpoint(medium down)} {
		.top-bar-left, .top-bar-right {
			float: none;
		}
		
		.columns {
			padding: 0;
		}
	}
}

@media screen and #{breakpoint(large)} {
	.top-bar-transparent {
		background: none;
		
		.menu {
			background: none;
			
			> li {
				color: white;
				
				a {
					color: white;
				}
				
				&:hover, &.is-active {
					background: none;
				}
			}
		}
		
		.flag {
			background: none;
		}
	}
}
@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

/* 
 * gyrodrive custom styles
 */

@import 'components/general';
@import 'components/animations';
@import 'components/header';
@import 'components/intro';
@import 'components/news';
@import 'components/form';
@import 'components/content';
@import 'components/gallery';
@import 'components/footer';

// homepage panels
@import 'panels/products';
@import 'panels/enjoy';
@import 'panels/fly';
@import 'panels/newsletter';
@import 'panels/technology';
@import 'panels/chess';

// single pages
@import 'pages/products';
@import 'pages/product';
@import 'pages/about';
@import 'pages/news';
@import 'pages/contact';
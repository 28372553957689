.news-detail {
	color: #707379;
	font-size: $global-font-size + rem-calc(1);
	
	.back {
		float: left;
	}
	
	.next-news {
		float: right;
		height: rem-calc(48);
		padding: rem-calc(11 76 11 26);
		background: $light-gray;
		border-radius: rem-calc(50);
		font-size: $global-font-size;
		line-height: 1.1;
		position: relative;
		transition: all .3s;
		-webkit-transition: all .3s;
		
		> span {
			display: block;
			color: rgba(0,0,0,0.5);
			text-transform: uppercase;
		}
		
		> strong {
			color: #000000;
		}
		
		&:after {
			display: block;
			content: "";
			width: rem-calc(48);
			height: rem-calc(48);
			background: $primary-color url('../images/ico-arrow-next.png') 50% 50% no-repeat;
			border-radius: rem-calc(24);
			position: absolute;
			top: 0;
			right: 0;
		}
		
		&:hover {
			background-color: #dbdbdb;
		}
	}
	
	.extra-padding-vertical {
		padding-top: rem-calc(50);
		padding-bottom: rem-calc(50);
	}
	
	.date {
		margin-bottom: rem-calc(30);
		
		b {
			color: $primary-color;
		}
		
		@media screen and #{breakpoint(large)} {
			margin-bottom: rem-calc(60);
		}
	}
	
	.gal-image {
		display: block;
		margin-bottom: rem-calc(16);
	}
	
	.gallery {
		margin-bottom: rem-calc(20);
		
		.row {
			margin: rem-calc(0 -8);
		}
		
		.columns {
			padding: rem-calc(0 8);
		}
		
		img {
			width: 100%;
		}
	}
}

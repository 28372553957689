.products-list {
	.product {
		position: relative;
		overflow: hidden;
		
		.product-image {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: bottom center;
			
			&.product-1 {
				background-image: url('../images/produkty/product-1-svetly-small.jpg');
			}
			
			&.product-2 {
				background-image: url('../images/produkty/product-2-svetly-small.jpg');
			}
			
			&.product-3 {
				background-image: url('../images/produkty/product-3-svetly-small.jpg');
			}
		}
		
		.product-content {
			padding: rem-calc(60 30 30 30);
			color: #ffffff;
			font-size: $global-font-size - rem-calc(1);
			position: relative;
			z-index: 2;
			
			.suptitle {
				opacity: 1;

				& + .title {
					text-shadow: 3px 3px 8px black, 3px 3px 8px black;
					margin-top: 0;
				}
			}

			h2{
				padding-left: 10%;
			}
			
			p {
				/*text-shadow: $global-text-shadow;*/
				text-shadow: 3px 3px 8px black, 3px 3px 8px black;
				font-size: rem-calc(20);
				font-weight: bold;
				padding-left: 10%;
				&.bigger-paragraph{
					/*text-shadow: none;
					font-weight: normal;
					padding: 10px;
					background: rgba(255,255,255,0.9);
					border-radius: 15px;
					box-shadow: 0 0 20px white, 0 0 10px white, 0 0 20px white;
					color: black;*/
					padding: 5% 10% 5% 10%;
					margin: 0;
					background-color: rgba(255,255,255,0.8);
					text-shadow: none;
					box-shadow: none;
					font-weight: normal;
					min-height: rem-calc(135);
					color: black;
				}
			}
		}
		
		@media screen and #{breakpoint(large)} {
			height: 100vh;
			
			.product-image {
				transition: all 3s;
				-webkit-transition: all 3s;
			}
			
			.product-content {
				padding: 0;
				position: absolute;
				left: rem-calc(2);
				right: rem-calc(2);
				/*bottom: 3%;*/
				bottom: 0;
			}
			
			&:hover {
				.product-image {
					transform: scale(1.1);
					-webkit-transform: scale(1.1);
				}
			}
		}
	}
}

#products {
	padding-top: rem-calc(40);
	
	h2 {
		transition: all .5s;
	}
	
	.columns:hover {
		h2 {
			color: $primary-color;
		}
	}
	
	.col-image {
		display: inline-block;
		width: rem-calc(137);
		height: rem-calc(137);
		position: relative;
		border-radius: 50%;
		overflow: hidden;
		
		.image {
			width: rem-calc(137);
			height: rem-calc(137);
			background-repeat: no-repeat;
			background-size: cover;
			border-radius: 50%;
			transition: all 1s;
		}
		
		&:before {
			display: block;
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: url('../images/col-image.png') no-repeat;
			background-size: cover;
			z-index: 2;
		}
		
		&:after {
			display: block;
			content: "";
			width: rem-calc(63);
			height: rem-calc(52);
			position: absolute;
			top: 0;
			right: rem-calc(5);
			z-index: 3;
		}
		
		&:hover {
			.image {
				transform: scale(1.1);
				-webkit-transform: scale(1.1);
			}
		}
		
		&.image-1 {
			.image {
				background-image: url('../images/col-image-1.jpg');
			}
			
			&:after {
				background-image: url('../images/col-image-1-ico.png');
			}
		}
		
		&.image-2 {
			.image {
				background-image: url('../images/col-image-2.jpg');
			}
			
			&:after {
				background-image: url('../images/col-image-2-ico.png');
			}
		}
		
		&.image-3 {
			.image {
				background-image: url('../images/col-image-3.jpg');
			}
			
			&:after {
				background-image: url('../images/col-image-3-ico.png');
			}
		}
	}
}
#enjoy {
	padding-top: rem-calc(40);
	/*background: $black url('../images/bg-panel-enjoy.jpg') center bottom fixed no-repeat;*/
	background: $black url('../images/Enjoy2.png') center bottom fixed no-repeat;
	background-size: cover;
	color: #ffffff;
	font-size: $global-font-size + rem-calc(3);
	
	.custom-column {
		margin: 0 auto;
	}
	
	.button {
		margin-top: rem-calc(140);
	}
}
#footer {
	padding: rem-calc(26 0);
	background: $dark-gray;
	color: #ffffff;

	.logo {
		display: block;
		width: rem-calc(163);
		height: rem-calc(42);
		margin: rem-calc(0 0 20 10);
		background: url('../images/logo.png') no-repeat;
		
		// medium up
		@media screen and #{breakpoint(medium)} {
			float: left;
			margin: rem-calc(0 70 0 0);
		}
	}
	
	.menu {
		margin-top: rem-calc(6);
		
		> li {
			> a {
				color: #ffffff;
				font-size: $global-font-size;
				font-weight: $global-weight-light;
				text-transform: uppercase;
			}
			
			// medium down (small)
			@media screen and #{breakpoint(medium down)} {
				display: inline-block;
				text-align: center;
				
				> a {
					padding: rem-calc(10);
				}
			}
		}
	}
}
#technology {
	padding-bottom: rem-calc(20);
	
	.technology {
		width: rem-calc(900);
		height: rem-calc(526);
		margin: rem-calc(20) auto 0 auto;
		background: url('../images/technology.jpg') center bottom no-repeat;
		position: relative;

		.point {
			display: none;
			width: rem-calc(65);
			height: rem-calc(65);
			position: absolute;
			border-radius: 50%;
			outline: none;
			
			.circle {
				width: rem-calc(39);
				height: rem-calc(39);
				margin: rem-calc(13);
				background: $primary-color;
				border-radius: 50%;
				position: relative;
				z-index: 2;
				
				&:before {
					display: block;
					content: "";
					width: rem-calc(3);
					height: rem-calc(14);
					background: #ffffff;
					position: absolute;
					top: rem-calc(12);
					left: rem-calc(18);
				}
				
				&:after {
					display: block;
					content: "";
					width: rem-calc(14);
					height: rem-calc(3);
					background: #ffffff;
					position: absolute;
					top: rem-calc(18);
					left: rem-calc(12);
				}
			}
			
			.pulse {
				background: $primary-color;
				width: rem-calc(65);
				height: rem-calc(65);
				border-radius: 50%;
				animation: pulse 2s ease-out infinite;
				-webkit-animation: pulse 2s ease-out infinite;
				position: absolute;
				top: 50%;
				left: 50%;
				margin: rem-calc(-32.5 0 0 -32.5);
				z-index: 1;
				opacity: 0;
			}
			
			&:hover {
				cursor: pointer;
				
				.circle, .pulse {
					background: black;
				}
			}
		}
	
		.point-1 {
			left: rem-calc(275);
			bottom: rem-calc(209);
		}
		
		.point-2 {
			left: rem-calc(305);
			bottom: rem-calc(209);
		}
		
		.point-3 {
			left: rem-calc(445);
			bottom: rem-calc(107);
		}
		
		.point-4 {
			left: rem-calc(445);
			bottom: rem-calc(50);
		}
		
		.point-5 {
			left: rem-calc(577);
			bottom: rem-calc(182);
		}
		
		&:hover {
			.point {
				display: block;
			}
		}
	}

	.button {
		margin-top: rem-calc(20);
		
		@media screen and #{breakpoint(medium)} {
			margin-top: rem-calc(30);
		}
		
		@media screen and #{breakpoint(large)} {
			margin-top: rem-calc(60);
		}
	}
}
#about-intro {
	background-image: url(../images/o-nas-6-cut.png);

	@media screen and #{breakpoint(large)} {
		min-height: rem-calc(500);
	}
}

#tradition {
	.extra-padding {
		@media screen and #{breakpoint(large)} {
			min-height: rem-calc(525);
		}
	}
	
	.image-wrap {
		.image-about-2 {
			position: absolute;
			bottom: rem-calc(-95);
			left: rem-calc(-58);
		}
		
		.image-about-3 {
			position: absolute;
			bottom: rem-calc(-158);
			left: rem-calc(220);
		}
		
		@media screen and #{breakpoint(large down)} {
			.image-about-2 {
				width: rem-calc(250);
			}
			
			.image-about-3 {
				width: rem-calc(300);
				left: rem-calc(170);
			}
		}
	}
}

#company {
	background-image: url('../images/bg-about-company.jpg');
}